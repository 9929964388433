import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'

import MyContext from './../../context/context'

// MUI
import { Avatar, Box, Button, CircularProgress, Container, CssBaseline, Stack, TextField, Typography } from '@mui/material'
import {  } from '@mui/icons-material'
import axios from "axios";

export default function CreateAccountUser({snackbar}){
    const api = useContext(MyContext)
    const referid = new URLSearchParams(window.location.search).get('referid')
    const [action, setAction] = useState('')
    const navi = useNavigate()
    const auth = Cookies.get('authuser')

    useEffect(() => {
        auth === 'user'  && navi('/user/profile')
    }, [])

    const [mobile, setMobile] = useState('')
    const [name, setName] = useState('')
    const [pass, setPass] = useState('')
    const [otp, setOtp] = useState('')
    const [otpsent, setOtpsent] = useState(false)

    const send_otp = () => {
        setAction('otpsending')
        var formData = new FormData()
        formData.append('send-otp-create-account', mobile)
        axios.post(api.api.login, formData)
        .then(function(res){
            setAction('')
            snackbar(res.data.msg)
            if(res.data.status === true){
                setOtpsent(true)
            }
        })
    }

    const create_account = () => {
        setAction('creating')
        var formData = new FormData();
        formData.append('create-user-account', mobile)
        formData.append('name', name)
        formData.append('otp', otp)
        formData.append('password', pass)
        formData.append('referby', referid)
        axios.post(api.api.login, formData)
        .then(function(res){
            setAction('')
            snackbar(res.data.msg)
            if(res.data.status === true){
                navi('/user/profile', {replace: true})
            }
        })
    }

    return(
        <Box>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                >
                    <img src="/logo.jpg" style={{width: 250}} />
                    <Typography component="h1" variant="h5" mt={3} >
                        Welcome to Aircraft-MFG
                    </Typography>
                    <Box component="form" noValidate sx={{ mt: 1 }}>
                        <Stack
                        direction={'row'}
                        spacing={0.5}
                        >
                        <TextField
                        required
                        fullWidth
                        type="number"
                        label="Mobile number"
                        autoFocus
                        value={mobile}
                        onChange={(event) => setMobile(event.target.value)}
                        />
                        <Button variant="outlined" sx={{width: 130}} onClick={() => send_otp()} disabled={otpsent === true ? true : action === 'otpsending' ? true : false} >{action === 'otpsending' ? <CircularProgress color="inherit" size={24} /> : 'Send OTP'}</Button>
                        </Stack>

                        <TextField
                        margin="normal"
                        required
                        fullWidth
                        type="text"
                        label="Name"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        />

                        <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Password"
                        type="text"
                        value={pass}
                        onChange={(event) => setPass(event.target.value)}
                        />

                        <TextField
                        margin="normal"
                        required
                        fullWidth
                        type="number"
                        label="OTP"
                        value={otp}
                        onChange={(event) => setOtp(event.target.value)}
                        />

                        <Button
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 4 }}
                        disabled={otpsent === false ? true : action === 'creating' ? true : false}
                        onClick={() => create_account()}
                        >
                        { action === 'creating' ? <CircularProgress color="inherit" size={24} /> :
                        'Sign In' }
                        </Button>
                    </Box>
                </Box>
            </Container>
        </Box>
    );

}