import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'

// MUI
import { Box, Button, Container, Stack, Typography } from '@mui/material'
import {  } from '@mui/icons-material'
import MyContext from './../context/context'
import axios from "axios";
import Lottie from 'lottie-react';
import animationData from './../failed.json';
import animationSuccess from './../success.json';

export default function VerifyPaymentUser(){
    const navi = useNavigate()
    const api = useContext(MyContext)
    var txnid = new URLSearchParams(window.location.search).get('client_txn_id')

    const [msg, setMsg] = useState([])

    useEffect(() => {
        axios.get(`${api.api.user}?verify-payment=${txnid}`)
        .then(function(res){
            setMsg(res.data)
        })
    }, [])

    return(
        <Box>
            <Container>
                <Stack sx={{height: '100vh'}} justifyContent={'center'} alignItems={'center'} >
                    {msg.status === true &&
                        <Box textAlign={'center'} >
                            <Box textAlign={'center'} >
                                <Lottie animationData={animationSuccess} style={{width: 300, margin: 'auto'}} />
                            </Box>
                            <Typography fontSize={25} textAlign={'center'} fontWeight={600} color={'success.main'} >{msg.msg}</Typography>
                            <Typography fontSize={14} color={'gray'} mt={2} >*We have successfully completed your request. Now you can continue your purchase.</Typography>
                        </Box>
                    }
                    {msg.status === false &&
                        <Box textAlign={'center'} >
                            <Box textAlign={'center'} >
                                <Lottie animationData={animationData} style={{width: 300, margin: 'auto'}} />
                            </Box>
                            <Typography fontSize={25} textAlign={'center'} fontWeight={600} color={'error'} >{msg.msg}</Typography>
                            <Typography fontSize={14} color={'gray'} mt={2} >*Kindly contact to our helpline number if your payment is deducted from your bank account</Typography>
                        </Box>
                    }
                </Stack>
            </Container>
            <Button sx={{position: 'fixed', bottom: 0, width: '100%', borderRadius: 0}} size="large" variant="contained" onClick={() => navi('/user/profile', {replace: true})} >Go to home</Button>
        </Box>
    );

}