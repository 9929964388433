import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'

import MyContext from "../context/context";

// MUI
import { Box, Button, Card, CardContent, Divider, Stack, Typography } from '@mui/material'
import { AccountBalanceWallet, History } from '@mui/icons-material'
import axios from "axios";

export default function BalanceUser(){
    const api = useContext(MyContext)
    let navi = useNavigate()

    const [wallet, setWallet] = useState([])
    useEffect(() => {
        axios.get(`${api.api.user}?wallet`)
        .then(function(res){
            res.data.status === true && setWallet(res.data.data)
        })
    }, [])

    return(
        <Box>
            <Card>
                <CardContent>
                    <Stack mb={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Stack direction={'row'} alignItems={'center'} >
                            <AccountBalanceWallet color="primary" sx={{fontSize: 60}} />

                            <Box textAlign={'left'} >
                                <Typography fontSize={12} color={'gray'} >Recharge wallet</Typography>
                                <Typography fontSize={22} fontWeight={600} color={'primary.main'} >&#8377; {parseInt(parseInt(wallet.recharge)-parseInt(parseInt(wallet.purchase))).toLocaleString('en-in')}</Typography>
                            </Box>
                        </Stack>
                        <Box>
                            <Button variant="outlined" color="warning" size="small" onClick={() => navi('/user/wallet-history')} startIcon={<History/>} >Record</Button>
                        </Box>
                    </Stack><Divider/>

                    <Stack mb={2} mt={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Box>
                            <Typography fontSize={10} color={'gray'} >Today Profit</Typography>
                            <Typography fontSize={20} fontWeight={600} >&#8377; {parseInt(wallet.credit_today).toLocaleString('en-in')}</Typography>
                        </Box>
                        <Box>
                            <Typography fontSize={10} color={'gray'} >Total Profit</Typography>
                            <Typography fontSize={20} fontWeight={600} >&#8377; {parseInt(wallet.total_income).toLocaleString('en-in')}</Typography>
                        </Box>
                        <Box>
                            <Typography fontSize={10} color={'gray'} >Balance</Typography>
                            <Typography fontSize={20} fontWeight={600} >&#8377; {(parseInt(wallet.total_income)- parseInt(wallet.total_expand)).toLocaleString('en-in')}</Typography>
                        </Box>
                    </Stack><Divider/>
                </CardContent>
            </Card>
        </Box>
    );

}