import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import MyContext from "../context/context";

// MUI
import { Box, Button, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, Input, InputAdornment, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { AccountTree, ContentCopy, Edit, Search } from '@mui/icons-material'
import axios from "axios";

export default function UserAdmin({snackbar}){
    const api = useContext(MyContext)
    const [open, setOpen] = useState('')
    const [action, setAction] = useState('')
    const navi = useNavigate()

    var url = new URLSearchParams(window.location.search).get('value')
    const [searchquery, setSearchquery] = useState(url?url:'')

    const [users, setUsers] = useState([])
    useEffect(() => {
        get_users()
    }, [])
    const get_users = () => {
        axios.get(`${api.api.admin}?users=${searchquery}`)
        .then(function(res){
            if(res.data.status === true){
                setUsers(res.data.data)
            } else {
                setUsers(res.data)
            }
        })
    }

    const [userid, setUserid] = useState('')
    const [name, setName] = useState('')
    const [mobile, setMobile] = useState('')
    const [status, setStatus] = useState('')
    const open_userdialog = (userid) => {
        setUserid(userid)
        setOpen('userdialog')
        get_user_details(userid)
    }
    const get_user_details = (userid) => {
        axios.get(`${api.api.admin}?user-details=${userid}`)
        .then(function(res){
            snackbar(res.data.msg)
            if(res.data.status === true){
                setName(res.data.data.name); setMobile(res.data.data.mobile); setStatus(res.data.data.status)
            }
        })
    }

    const update_user_details = () => {
        setAction('updating')
        var formData = new FormData()
        formData.append('update-user', userid)
        formData.append('name', name); formData.append('mobile', mobile); formData.append('status', status)
        axios.post(api.api.admin, formData)
        .then(function(res){
            setAction('')
            snackbar(res.data.msg)
            if(res.data.status === true){
                get_users()
                setOpen('')
            }
        })
    }

    const search_user = (event) => {
        if(event.key === 'Enter'){
            navi(`?value=${event.target.value}`, {replace: true})
            get_users()
        }
    }

    const [bank, setBank] = useState([])
    const get_bank_details = (userid) => {
        setBank([])
        setOpen('bank')
        axios.get(`${api.api.admin}?user-bank-details=${userid}`)
        .then(function(res){
            snackbar(res.data.msg)
            res.data.status === true && setBank(res.data.data)
        })
    }

    const handleCopy = (event) => {
        navigator.clipboard.writeText(event)
        .then(() => snackbar('Copied to clipboard'))
        .catch(() => snackbar('Failed to copy'));
    };


    return(
        <Box className='content' mb={{xs: 10, md: 0}} >
            <Container>

                <Dialog open={open === 'userdialog' ? true : false} fullWidth maxWidth='xs' onClose={() => setOpen('')} >
                    <DialogTitle>User detail</DialogTitle>
                    <Divider/>
                    <DialogContent>
                        <Stack spacing={1.2}>
                            <TextField fullWidth variant="outlined" label='Name' value={name} onChange={(event)=> setName(event.target.value)} type="text" />
                            <TextField fullWidth variant="outlined" label='Mobile' value={mobile} onChange={(event) => setMobile(event.target.value)} type="number" />
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={status}
                                    label="Status"
                                    onChange={(event) => setStatus(event.target.value)}
                                >
                                    <MenuItem value={'active'}>Active</MenuItem>
                                    <MenuItem value={'pending'}>Pending</MenuItem>
                                    <MenuItem value={'blocked'}>Blocked</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                    </DialogContent>
                    <Divider/>
                    <DialogActions>
                        <Button variant="outlined" color="inherit" onClick={() =>  setOpen('')} >Close</Button>
                        <Button variant="contained" color="primary" disabled={action === 'updating' ? true : false} onClick={() => update_user_details()} >{action === 'updating' ? <CircularProgress color="inherit" size={24} /> : 'Update'}</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={open === 'bank' ? true : false} fullWidth maxWidth='xs' onClose={() => setOpen('')} >
                    <DialogContent>
                        <Stack mb={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Box>
                                <Typography fontSize={10} color={'gray'} >Account holder name</Typography>
                                <Typography fontSize={14} color={'primary.main'} fontWeight={500} >{bank.name}</Typography>
                            </Box>
                            <IconButton onClick={() => handleCopy(bank.name)} ><ContentCopy/></IconButton>
                        </Stack><Divider/>
                        <Stack mt={1} mb={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Box>
                                <Typography fontSize={10} color={'gray'} >Bank name</Typography>
                                <Typography fontSize={14} color={'primary.main'} fontWeight={500} >{bank.bank_name}</Typography>
                            </Box>
                            <IconButton onClick={() => handleCopy(bank.bank_name)} ><ContentCopy/></IconButton>
                        </Stack><Divider/>
                        <Stack mt={1} mb={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Box>
                                <Typography fontSize={10} color={'gray'} >IFSC</Typography>
                                <Typography fontSize={14} color={'primary.main'} fontWeight={500} >{bank.ifsc}</Typography>
                            </Box>
                            <IconButton onClick={() => handleCopy(bank.ifsc)} ><ContentCopy/></IconButton>
                        </Stack><Divider/>
                        <Stack mt={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Box>
                                <Typography fontSize={10} color={'gray'} >Account number</Typography>
                                <Typography fontSize={14} color={'primary.main'} fontWeight={500} >{bank.account}</Typography>
                            </Box>
                            <IconButton onClick={() => handleCopy(bank.account)}><ContentCopy/></IconButton>
                        </Stack>
                    </DialogContent>
                </Dialog>

                <FormControl variant="standard" fullWidth sx={{mb: 3}} >
                    <InputLabel htmlFor="outlined-adornment-password">Search user</InputLabel>
                    <Input
                        id="outlined-adornment-password"
                        type='text'
                        placeholder="Search by user ID..."
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={() => search_user()}
                            >
                            <Search />
                            </IconButton>
                        </InputAdornment>
                        }
                        label="Search user"
                        value={searchquery}
                        onChange={(event) => setSearchquery(event.target.value)}
                        onKeyUp={(event) => search_user(event)}
                    />
                </FormControl>

                <TableContainer component={Paper} sx={{height: {xs: 'auto', md: 520}}} >
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell align="left">User ID</TableCell>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Mobile</TableCell>
                                <TableCell align="left">Status</TableCell>
                                <TableCell align="left">Details</TableCell>
                                <TableCell align="right">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.status !== false && users.map((row, i) =>
                            <TableRow key={i}>
                                <TableCell align="left">{users.length-i}</TableCell>
                                <TableCell align="left">{row.userid}</TableCell>
                                <TableCell align="left">{row.name}</TableCell>
                                <TableCell align="left">{row.mobile}</TableCell>
                                <TableCell align="left">
                                    <Chip variant="outlined" color={row.status === 'active' ? 'primary' : row.status === 'pending' ? 'warning' : row.status === 'blocked' ? 'error' : 'default'} label={row.status} sx={{textTransform: 'capitalize'}} />
                                </TableCell>
                                <TableCell align="left">
                                    <Chip variant="contained" color="secondary" label='Details' clickable icon={<AccountTree/>} onClick={() => get_bank_details(row.userid)} />
                                </TableCell>
                                <TableCell align="right">
                                    <Chip variant="contained" color="primary" label='Edit' clickable icon={<Edit/>} onClick={() => open_userdialog(row.userid)} />
                                </TableCell>
                            </TableRow> ) }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </Box>
    );

}