import React, { useContext, useEffect, useState } from "react";
import {  } from 'react-router-dom'

import BalanceUser from "./balance";
import MyContext from "../context/context";

// MUI
import { Box, Chip, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import axios from "axios";

export default function WalletHistoryUser(){
    const api = useContext(MyContext)

    const [wallet, setWallet] = useState([])
    useEffect(() => {
        axios.get(`${api.api.user}?wallet-history`)
        .then(function(res){
            res.data.status === true ? setWallet(res.data.data) : setWallet(res.data)
        })
    }, [])

    return(
        <Box className='content' mb={{xs: 10, md: 2}} >
            <Container>
                <BalanceUser />

                <TableContainer component={Paper} sx={{mt: 2}} >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" >#</TableCell>
                                <TableCell align="left">Amount</TableCell>
                                <TableCell align="left">Date</TableCell>
                                <TableCell align="left">Status</TableCell>
                                <TableCell align="right">Type</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {wallet.status !== false && wallet.map((row, i) => (
                            <TableRow
                            key={i}
                            >
                            <TableCell align="left" >{wallet.length - i}</TableCell>
                            <TableCell align="left">&#8377; {parseInt(row.amount).toLocaleString('en-in')}</TableCell>
                            <TableCell align="left">{row.date}</TableCell>
                            <TableCell align="left">{row.description}</TableCell>
                            <TableCell align="right">
                                <Chip label={row.status} sx={{textTransform: 'capitalize'}} variant="contained" color={row.status === 'debit' ? 'error' : 'success'} icon={row.status === 'debit' ? <ArrowUpward /> : <ArrowDownward />} />
                            </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </Box>
    );

}