import React, { useContext } from "react";
import {  } from 'react-router-dom'

// MUI
import { Accordion, AccordionDetails, AccordionSummary, Box, Container } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import MyContext from "../context/context";

export default function TeamRulesUser(){
    const api = useContext(MyContext)
    const rules = [
        {heading: 'Team rules', img:'/team_rule.jpg'},
        {heading: 'Vip upgrade', img:'/vip-upgrade.jpeg'},
        {heading: 'Daily income', img:'/daily_income.jpeg'},
        {heading: 'Daily income team commision', img:'/daily_income_commision.jpeg'},
        {heading: 'Daily income invite bonus', img:'/daily_income_invite_bonus.jpeg'},
        {heading: 'Postpaid income', img:'/postpaid_income.jpg'},
        {heading: 'Postpaid team commission', img:'/postpaid_team_commision.jpg'},
        {heading: 'Postpaid invite bonus', img:'/postpaid_invite_bonus.jpg'}
    ]

    return(
        <Box className='content' mb={{xs: 10, md: 3}} >
            <Container>
                { rules.map((row) =>
                <Accordion key={row.heading} >
                    <AccordionSummary
                    expandIcon={<ExpandMore />}
                    >
                        {row.heading}
                    </AccordionSummary>
                    <AccordionDetails>
                        <img src={api.api.img+row.img} />
                    </AccordionDetails>
                </Accordion> ) }
            </Container>
        </Box>
    );

}