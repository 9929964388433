import React, { useContext, useEffect, useState } from "react";
import {  } from 'react-router-dom'

// MUI
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Container, Stack, Typography } from '@mui/material'
import { Check, CheckBox, ExpandMore } from '@mui/icons-material'
import axios from "axios";
import MyContext from "../context/context";

export default function TeamUser(){
    const api = useContext(MyContext)

    const [teamB, setTeamB] = useState([])
    const [teamC, setTeamC] = useState([])
    const [teamD, setTeamD] = useState([])
    
    useEffect(() => {
        axios.get(`${api.api.user}?my-team`)
        .then(function(res){
            res.data.teamB.status === true ? setTeamB(res.data.teamB.data) : setTeamB(res.data.teamB)
            res.data.teamC.status === true ? setTeamC(res.data.teamC.data) : setTeamC(res.data.teamC)
            res.data.teamD.status === true ? setTeamD(res.data.teamD.data) : setTeamD(res.data.teamD)
        })
    }, [])

    return(
        <Box className='content'>
            <Container>
                <Accordion defaultExpanded >
                    <AccordionSummary
                    expandIcon={<ExpandMore />}
                    >
                    <Typography fontWeight={'bold'}>Level B - {teamB.length} </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        { teamB.status === false ? <Alert severity="warning">{teamB.msg}</Alert> :
                        teamB.map((row, i) =>
                        <Stack mb={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'} key={i} >
                            <Box>
                                <Typography fontWeight={500} color={'primary.main'} >{row.userid}</Typography>
                                <Typography fontSize={12} color={'gray'} >{new Date(row.joindate).toLocaleDateString(undefined, {day: '2-digit', month: 'long', year: 'numeric'})}</Typography>
                            </Box>
                            <CheckBox color="success" />
                        </Stack>) }
                    </AccordionDetails>
                </Accordion>
                <Accordion >
                    <AccordionSummary
                    expandIcon={<ExpandMore />}
                    >
                    <Typography fontWeight={'bold'}>Level C - {teamC.length}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        { teamC.status === false ? <Alert severity="warning">{teamC.msg}</Alert> :
                        teamC.map((row, i) =>
                        <Stack mb={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'} key={i} >
                            <Box>
                                <Typography fontWeight={500} color={'primary.main'} >{row.userid}</Typography>
                                <Typography fontSize={12} color={'gray'} >{new Date(row.joindate).toLocaleDateString(undefined, {day: '2-digit', month: 'long', year: 'numeric'})}</Typography>
                            </Box>
                            <CheckBox color="success" />
                        </Stack>) }
                    </AccordionDetails>
                </Accordion>
                <Accordion >
                    <AccordionSummary
                    expandIcon={<ExpandMore />}
                    >
                    <Typography fontWeight={'bold'}>Level D - {teamD.length}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        { teamD.status === false ? <Alert severity="warning">{teamD.msg}</Alert> :
                        teamD.map((row, i) =>
                        <Stack mb={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'} key={i} >
                            <Box>
                                <Typography fontWeight={500} color={'primary.main'} >{row.userid}</Typography>
                                <Typography fontSize={12} color={'gray'} >{new Date(row.joindate).toLocaleDateString(undefined, {day: '2-digit', month: 'long', year: 'numeric'})}</Typography>
                            </Box>
                            <CheckBox color="success" />
                        </Stack>) }
                    </AccordionDetails>
                </Accordion>
            </Container>
        </Box>
    );

}