import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import MyContext from "../context/context";
import BalanceUser from "./balance";

// MUI
import { Avatar, Box, Button, Card, CardContent, Chip, CircularProgress, Container, Divider, Grid, Stack, Typography } from '@mui/material'
import { ShoppingCartCheckout } from '@mui/icons-material'
import axios from "axios";

export default function UserProductsUser({snackbar}){
    const api = useContext(MyContext)
    const img = useContext(MyContext)
    const [action, setAction] = useState([])

    const navi = useNavigate()
    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    useEffect(() => {
        get_products()
    }, [])

    const [products, setProducts] = useState([])
    const get_products = () => {
        axios.post(`${api.api.user}?my-products`)
        .then(function(res){
            if(res.data.status === false){
                setProducts(res.data)
            } else {
                setProducts(res.data.data)
            }
        })
    }

    const claim_profit = (purchaseid) => {
        setAction(purchaseid)
        var formData = new FormData()
        formData.append('claim-profit', purchaseid)
        axios.post(api.api.user, formData)
        .then(function(res){
            setAction('')
            snackbar(res.data.msg)
            res.data.status === true && get_products()
        })
    }

    return(
        <Box className='content' mb={10} >
            <Container>
                <BalanceUser />
                <Stack mt={3} mb={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography fontSize={25} fontWeight={600} color={'primary.main'} >Engines</Typography>
                </Stack>
                <Grid container spacing={2} >
                    { products.status !== false && products.map((row, i) =>
                    <Grid item xs={12} md={4} key={i} >
                        <Card>
                            <CardContent>
                                <Stack direction={'row'} justifyContent={'start'} alignItems={'center'} spacing={1.5} >
                                    <Avatar sx={{borderRadius: 1, width: 100, height: 100}} src={`${img.api.img}/${row.productid}.jpg`} />
                                    <Box>
                                        <Typography fontSize={20} fontWeight={600} >{row.name}</Typography>
                                        <Chip label={row.daily === '1' ? 'Daily income' : 'Post paid'} variant="outlined" size="small" color="primary" />
                                        <Typography mt={1} fontSize={20} fontWeight={600} >&#8377; {parseInt(row.price).toLocaleString('en-in')}</Typography>
                                    </Box>
                                </Stack>
                                <Stack mt={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'}
                                divider={<Divider orientation="vertical" flexItem />} >
                                    <Box>
                                        <Stack direction={'row'} alignItems={'center'} spacing={1} >
                                            <Typography fontSize={12} color={'GrayText'} >Daily Income: </Typography>
                                            <Typography fontSize={16} fontWeight={600} >&#8377; {parseInt(row.daily_income).toLocaleString('en-in')}</Typography>
                                        </Stack>
                                        <Stack direction={'row'} alignItems={'center'} spacing={1} >
                                            <Typography fontSize={12} color={'GrayText'} >Total Income: </Typography>
                                            <Typography fontSize={16} fontWeight={600} color={'primary.main'} >&#8377; {parseInt(row.profit).toLocaleString('en-in')}</Typography>
                                        </Stack>
                                    </Box>
                                    <Box>
                                        <Typography fontSize={16} fontWeight={600} >{row.period} Days</Typography>
                                        <Typography fontSize={12} color={'GrayText'} >Periods of validity</Typography>
                                    </Box>
                                </Stack>
                                <Stack mb={1} mt={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography fontSize={12} color={'gray'} >Purchase date</Typography>
                                    <Chip size="small" variant="contained" color="primary" label={new Date(row.purchase_date).toLocaleDateString(undefined, {day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit', hourCycle: 'h12'})} />
                                </Stack>
                                <Divider/>
                                <Stack mt={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography fontSize={12} color={'gray'} >{row.daily === '1' ? 'Claim date' : 'Expiry date'}</Typography>
                                    <Chip size="small" variant="contained" color="success" label={new Date(new Date(row.purchase_date).setDate(new Date(row.purchase_date).getDate() + parseInt(row.period))).toLocaleDateString(undefined, {day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit', hourCycle: 'h12'})} />
                                </Stack>
                                
                                <Stack direction={'row'} mt={2} spacing={0.5} >
                                    <Button variant="contained" color="primary" sx={{width: '100%'}} startIcon={<ShoppingCartCheckout />} disabled={row.purchaseid === action ? true : row.purchase_status === 'closed' ? true : false} onClick={() => claim_profit(row.purchaseid)}  >{row.purchaseid === action ? <CircularProgress color="inherit" size={24} /> : 'Claim profit'}</Button>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid> ) }
                </Grid>
            </Container>
        </Box>
    );

}