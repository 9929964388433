import React, { useState } from "react";
import { Route, Routes } from 'react-router-dom'
import { ThemeProvider } from "@emotion/react"

// MUI
import { Snackbar, createTheme } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles';

// USER
import LoginUser from "./login/user/login";
import ResetUser from "./login/user/reset";
import CreateAccountUser from "./login/user/create";
import MyState from "./context/state";
import SidebarUser from "./user/sidebar";
import HomeUser from "./user/index";
import DeviceUser from "./user/device";
import ProfileUser from "./user/profile";
import AboutUser from "./user/about";
import NewsdetailUser from "./user/news-detail";
import DeviceDetailsUser from "./user/device-details";
import UserProductsUser from "./user/my-products";
import WalletHistoryUser from "./user/wallet-history";
import SupportUser from "./user/support";
import WithdrawUser from "./user/withdraw";
import RechargeUser from "./user/recharge";
import FAQ from "./user/faq";
import TeamUser from "./user/team";
import VerifyPaymentUser from "./user/verify-payment";
import TeamRulesUser from "./user/team-rules";
import GiftAdmin from "./admin/gift";
import LightApp from "./user/light-app";

// Admin
import LoginAdmin from "./login/admin/login";
import ResetAdmin from "./login/admin/reset";
import SidebarAdmin from "./admin/sidebar";
import HomeAdmin from "./admin";
import DeviceAdmin from "./admin/device";
import AddDevice from "./admin/device-details";
import UserAdmin from "./admin/user";
import NewsdetailAdmin from "./admin/news-detail";
import WithdrawRequestAdmin from "./admin/withdraw-request";

function App() {

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1300,
        xl: 1920
      },
    },
    palette: {
      primary: {
        light: '#6DB6FF',
        main: '#007FFF',
        dark: '#0052A4',
        contrastText: '#FFFFFF',
      },
      secondary: {
        light: '#FD912D',
        main: '#ED7603',
        dark: '#884201',
        contrastText: '#000000'
      },
      error: {
        main: '#FF0800'
      }
    },
    typography: {
      button: {
        textTransform: 'initial'
      },
    },

    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: 'none'
          }
        }
      }
    }
  })

  const fullscreen = useMediaQuery(useTheme().breakpoints.down('md'));

  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const handlesnackbar = (data) => {
    setMessage(data); setOpen(true)
  }

  return (
    <MyState>
      <ThemeProvider theme={theme} >
        <Snackbar open={open} message={message} onClose={() => setOpen(false)} anchorOrigin={{vertical: 'top', horizontal: 'right'}} />
        <Routes>
          {/* Login */}
          <Route path="/login-user" element={<LoginUser snackbar={handlesnackbar} />} />
          <Route path="/reset-user" element={<ResetUser snackbar={handlesnackbar} />} />
          <Route path="/create-user" element={<CreateAccountUser snackbar={handlesnackbar} />} />

          {/* Login admin */}
          <Route path="/login-admin" element={<LoginAdmin snackbar={handlesnackbar} />} />
          <Route path="/reset-admin" element={<ResetAdmin snackbar={handlesnackbar} />} />

          {/* Admin */}
          <Route path="/admin" element={<SidebarAdmin snackbar={handlesnackbar} />}>
            <Route path="home" element={<HomeAdmin snackbar={handlesnackbar} />} />
            <Route path="device" element={<DeviceAdmin snackbar={handlesnackbar} />} />
            <Route path="add-device" element={<AddDevice snackbar={handlesnackbar} />} />
            <Route path="user" element={<UserAdmin snackbar={handlesnackbar} />} />
            <Route path="read-news" element={<NewsdetailAdmin />} />
            <Route path="withdraw-request" element={<WithdrawRequestAdmin snackbar={handlesnackbar} />} />
            <Route path="gift" element={<GiftAdmin snackbar={handlesnackbar} />} />
          </Route>

          {/* User */}
          <Route path="/user" element={<SidebarUser />}>
            <Route path="home" element={<HomeUser />} />
            <Route path="device" element={<DeviceUser />} />
            <Route path="profile" element={<ProfileUser fullscreen={fullscreen} snackbar={handlesnackbar} />} />
            <Route path="about" element={<AboutUser />} />
            <Route path="read-news" element={<NewsdetailUser />} />
            <Route path="device-detail" element={<DeviceDetailsUser snackbar={handlesnackbar} />} />
            <Route path="my-products" element={<UserProductsUser snackbar={handlesnackbar} />} />
            <Route path="wallet-history" element={<WalletHistoryUser />} />
            <Route path="support" element={<SupportUser />} />
            <Route path="withdraw" element={<WithdrawUser snackbar={handlesnackbar} />} />
            <Route path="recharge" element={<RechargeUser snackbar={handlesnackbar} />} />
            <Route path="team" element={<TeamUser />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="team-rules" element={<TeamRulesUser />} />
            <Route path="light-app" element={<LightApp />} />
          </Route>

          <Route path="verify-payment" element={<VerifyPaymentUser />} />

        </Routes>
      </ThemeProvider>
    </MyState>
  );
}
export default App;